import { ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from '@ag-grid-community/all-modules';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiDataService } from 'src/app/core/services/api-data.service';
import { ClientSideRowModelModule, RichSelectModule } from '@ag-grid-enterprise/all-modules';
import { FilterService } from '../filter/filter.service';
import { Subscription } from 'rxjs';
import { SaveService } from '../save-button/save.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';

interface IData {
  [key: string]: string | number;
}

export interface ValidatorFunction {
  (data: IData): string | null
}

@Component({
  selector: 'app-maintenance-table',
  templateUrl: './maintenance-table.component.html',
  styleUrls: ['./maintenance-table.component.scss']
})
export class MaintenanceTableComponent implements OnInit, OnDestroy {

  @Input() columnDefs: ColDef[] = [];
  @Input() keyFields: string[] = [];
  @Input() mandatoryFields: string[] = [];
  @Input() apiUrl = '';
  @Input() validators: ValidatorFunction[] = [];


  @ViewChild('insertDialog') insertDialog!: TemplateRef<unknown>;

  gridOptions: GridOptions = {};
  gridReady?: GridReadyEvent;
  modules = [ClientSideRowModelModule, RichSelectModule];
  data: IData[] | null = null;
  hasWriteRole!: boolean;


  newRowForm!: FormGroup;
  formControls: {
    [field: string]: FormControl
  } = {};

  private filterSubscription!: Subscription;
  private saveSubscription!: Subscription;
  private dialogRef!: MatDialogRef<unknown, boolean>;
  private invalidRows = 0;

  constructor(
    private apiService: ApiDataService,
    private filterService: FilterService,
    private saveService: SaveService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }


  ngOnInit(): void {
    this.hasWriteRole = this.authService.hasWriteRole();

    this.columnDefs = this.columnDefs.map(c => { return { ...c, editable: c.editable ? this.hasWriteRole : c.editable } });

    this.filterSubscription = this.filterService.filterValues$.subscribe(() => {
      this.gridReady?.api.onFilterChanged();
    });
    this.saveSubscription = this.saveService.saveRequest$.subscribe(() => {
      if (!this.data) {
        this.snackBar.open('No data loaded', undefined, { duration: 8000 });
        return;
      }

      if (this.invalidRows != 0) {
        this.snackBar.open("Some cells shouldn't be left empty", undefined, { duration: 8000 });
        return;
      }

      if (this.gridReady?.api.getEditingCells().length) {
        this.snackBar.open('Finish editing before saving', undefined, { duration: 8000 });
        return;
      }

      const payload = this.data.filter(row => row['RECORD_MODE'] && row['RECORD_MODE'] != 'DI').map(row => {
        const data = { ...row };
        delete data['tmp_selection'];
        return { ...data, RECORD_MODE: (data['RECORD_MODE'] as string)[0], RecordMode: (data['RECORD_MODE'] as string)[0] };
      });

      if (!payload.length) {
        this.snackBar.open('No changes to be saved', undefined, { duration: 8000 });
        this.apiService.getData<IData[]>(this.apiUrl).subscribe(data => this.data = data);
        return;
      }

      for (const row of (payload as IData[])) {
        for (const validator of this.validators) {
          const returnVal = validator(row);
          if (returnVal) {
            this.snackBar.open(returnVal, undefined, { duration: 8000 });
            return;
          }
        }
      }

      const request = this.apiService.postData<string>(this.apiUrl, {}, payload);

      this.saveService.addPendingRequest(request);
      request.subscribe((response: string) => {
        this.snackBar.open(response, undefined, { duration: 8000 });
        this.gridReady?.api.showLoadingOverlay();
        this.apiService.getData<IData[]>(this.apiUrl).subscribe(data => this.data = data);
      })
    });

    const selectColumn: ColDef[] = [];
    if (this.hasWriteRole)
      selectColumn.push({
        field: 'tmp_selection',
        headerName: 'Delete Row',
        minWidth: 80,
        // headerCheckboxSelection: true,
        checkboxSelection: true,
      })

    this.gridOptions = {
      rowSelection: 'multiple',
      rowMultiSelectWithClick: false,
      suppressRowClickSelection: true,
      onCellEditingStopped: (params) => {
        if (params.oldValue == params.newValue) return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const invalid = this.mandatoryFields.map(f => !params.data[f]).reduce((a, b) => a || b, false);

        this.invalidRows = invalid ? ++this.invalidRows : Math.max(0, this.invalidRows - 1);

        if (!params.data['RECORD_MODE'])
          params.data['RECORD_MODE'] = 'U';
        else {
          if ((params.data['RECORD_MODE'] as string).includes('I') || (params.data['RECORD_MODE'] as string).includes('U'))
            return;
          if (params.data['RECORD_MODE'] == 'D')
            params.data['RECORD_MODE'] += 'U';
        }
        params.node.setData(params.data);
      },
      onRowSelected: (event: RowSelectedEvent) => {
        if (event.node.isSelected()) {
          if (event.data['RECORD_MODE'])
            event.data['RECORD_MODE'] = 'D' + event.data['RECORD_MODE'];
          else
            event.data['RECORD_MODE'] = 'D';
        } else {
          if ((event.data['RECORD_MODE'] as string).length > 1)
            event.data['RECORD_MODE'] = event.data['RECORD_MODE'][1];
          else
            event.data['RECORD_MODE'] = null;
        }
        event.node.setData(event.data);
      },
      columnDefs: [
        ...selectColumn,
        ...this.columnDefs.map((c) => {
          c.cellStyle = (params) => {
            let recordMode: string | null = null;
            if (params.data)
              recordMode = params.data['RECORD_MODE'];
            const flag = recordMode ? recordMode[0] : recordMode;

            switch (flag) {
              case 'U':
                return { 'background-color': '#ffdf80', 'color': 'black' };
              case 'D':
                return { 'background-color': '#ffacac', 'color': 'black' };
              case 'I':
                return { 'background-color': '#8ac38a', 'color': 'black' };
              default:
                return { 'background-color': 'unset', 'color': 'unset' };
            }
          }
          return c;
        }), {
          field: 'RECORD_MODE',
          hide: true
        }],
      isExternalFilterPresent: () => this.filterService.filterValues != null,
      doesExternalFilterPass: (params) => {
        let pass = false;
        if (params.data && this.filterService.filterValues) {
          for (const field of this.keyFields) {
            pass = pass || (params.data[field] ?? '').toString().toLowerCase().includes(this.filterService.filterValues.filter.toLowerCase());
          }
        }
        return pass;
      },
      pagination: false,
    };

    this.formControls = this.columnDefs.reduce<typeof this.formControls>((controls, col) => {
      const field = col.field ?? '';

      const validators: ValidatorFn[] = [];
      if (this.keyFields.includes(field) || this.mandatoryFields.includes(field)) {
        validators.push(Validators.required);
      }

      controls[field] = new FormControl(null, { validators: validators });

      return controls
    }, {});

    this.newRowForm = new FormGroup(this.formControls);

    this.apiService.getData<IData[]>(this.apiUrl).subscribe(data => this.data = data);
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
  }

  submit() {
    if (!this.data) return;
    const dataKeys = this.data.map((row => this.keyFields.map(key => row[key].toString().toLowerCase()).join('-')));
    const newRowKey = this.keyFields.map(key => this.newRowForm.value[key].toString().toLowerCase()).join('-');

    if (dataKeys.includes(newRowKey)) {
      for (const key of this.keyFields)
        this.newRowForm.controls[key].setErrors({ 'notUnique': `Provided key is not unique` });
    }
    else { this.dialogRef.close(true) }
  }

  createRow() {
    if (!this.data) return;
    this.newRowForm.reset();
    this.dialogRef = this.dialog.open(this.insertDialog);
    this.dialogRef.beforeClosed().subscribe((finished?: boolean) => {
      if (!this.data || !finished) return;
      this.data = [{
        ...this.newRowForm.value,
        'RECORD_MODE': 'I'
      }, ...this.data]
      this.gridReady?.api.setRowData(this.data);
    });
  }
  getError(formControl: FormControl): string {
    if (formControl.hasError('required'))
      return 'Field Required';
    if (formControl.hasError('notUnique'))
      return formControl.getError('notUnique') as string;
    return '';
  }

  updateError(field: string) {
    if (this.keyFields.includes(field)) {
      for (const key of this.keyFields) {
        const errors = this.newRowForm.controls[key].errors;
        if (errors)
          delete errors['notUnique']
        this.newRowForm.controls[key].updateValueAndValidity()
      }
    }
  }

}
