import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UnityComponentsModule, UnityLayoutsModule, UnityServicesModule } from '@exxonmobil/angular-unity';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UnityComponentsModule,
    UnityServicesModule,
    UnityLayoutsModule,
    ComponentsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    HttpClientModule,
    MatDialogModule,
    MsalModule.forRoot(
      new PublicClientApplication(environment.authConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.tokenConsentScopes
        }
      }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [environment.apiRootUrl, environment.tokenConsentScopes]
      ])
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
