import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";

/**
 * Deals with the "save" signal and the pending save requests.
 */
@Injectable({
  providedIn: 'root'
})
export class SaveService {
  private _saveRequest: Subject<boolean> = new Subject();
  saveRequest$ = this._saveRequest.asObservable();
  private pendingRequests: Subscription[] = [];

  private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /**
   * This value will be `false` when there's no more pending requests being tracked.
   */
  isLoading$ = this._isLoading.asObservable();

  /**
   * Send "save" signal
   */
  sendSignal() {
    this._saveRequest.next(true);
  }

  /**
   * Add HTTP request to the pending requests list. When the request is completed, it'll be removed from the list.
   * If the list is empty, that means that all the data is saved.
   */
  addPendingRequest(req: Observable<unknown>) {
    const subscription = req.subscribe(() => {
      const index = this.pendingRequests.indexOf(subscription);
      if (index > -1) {
        this.pendingRequests.splice(index, 1);
      }
      if (this.pendingRequests.length === 0) this._isLoading.next(false);
    })
    this.pendingRequests.push(subscription);
    this._isLoading.next(true);
  }
}