import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CURRENCY_UOM_MAPPING_COLDEFS } from './currency-uom-mapping-col-defs';

@Component({
  selector: 'app-currency-uom-mapping',
  templateUrl: './currency-uom-mapping.component.html',
  styleUrls: ['./currency-uom-mapping.component.scss']
})
export class CurrencyUomMappingComponent {
  url = environment.apiRootUrl + environment.apiEndpoints['currencyUomMapping'];
  colDefs = CURRENCY_UOM_MAPPING_COLDEFS;
  keyColumns =[
    'QuotationKey'
  ]
}
