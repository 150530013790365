import { LogLevel } from "@azure/msal-common";
import { EnvDefinition } from "./env-definition";

export const environment: EnvDefinition = {
  production: false,
  name: 'DEV',
  apiRootUrl: 'https://ext.apiproxydev.exxonmobil.com/traderisk-rar-tablemaintenance/v1',
  apiEndpoints: {
    vendorQuoteMapping: '/vendorQuoteMapping',
    currencyUomMapping: '/currencyUomMapping',
    curveMapping: '/varCurveMapping'
  },
  authConfig: {
    auth: {
      clientId: 'api://4b1337b7-7d94-44c9-ba03-e62e42218e39',
      authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906',
      redirectUri: `${window.location.origin}`,
      navigateToLoginRequestUrl: true,
      postLogoutRedirectUri: `${window.location.origin}`,
    },
    cache: {
      cacheLocation:'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled:false
      },
      loadFrameTimeout: 10000
    }
  },
  tokenConsentScopes: ['api://4b1337b7-7d94-44c9-ba03-e62e42218e39/user_impersonation']
};
