import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CURVE_MAPPING_COLDEFS } from './curve-mapping-col-defs';
import { ValidatorFunction } from 'src/app/components/maintenance-table/maintenance-table.component';

@Component({
  selector: 'app-curve-mapping',
  templateUrl: './curve-mapping.component.html',
  styleUrls: ['./curve-mapping.component.scss']
})
export class CurveMappingComponent {
  url = environment.apiRootUrl + environment.apiEndpoints['curveMapping'];
  colDefs = CURVE_MAPPING_COLDEFS;
  keyColumns = [
    'QUOTE_SOURCE',
    'QUOTE_TYPE',
    'QUOTATION',
    'COMPONENT',
  ];
  mandatory = [
    'QUOTATION_GRANULARITY',
    'COMP_QUOTE_SOURCE',
    'COMP_QUOTE_TYPE',
    'COMP_QUOTATION',
    'COMP_GRANULARITY',
    'COMP_FACTOR'
  ];

  validators: ValidatorFunction[] = [
    (data) => data['QUOTATION_GRANULARITY'] == 'M' && data['COMP_GRANULARITY'] == 'D' ?
      "Component Granularity can't be 'D' (daily) when Quotation Granularity is 'M' (monthly)" :
      null

  ];

}
