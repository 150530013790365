import { Component } from '@angular/core';
import { FilterService } from './filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  filterValue = '';

  constructor(private filterService: FilterService) { }

  apply() {
    this.filterService.setFilterValues({ filter: this.filterValue });
  }

  clearFilter() {
    this.filterValue = '';
    this.filterService.setFilterValues(null);
  }
}
