import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';

/**
 * Service tha wraps HTTP requests with error-handling logic.
 * 
 * It also can issue a "reload" signal.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  constructor(private http: HttpClient, private errorHandler: ErrorHandlerService) { }

  getData<T>(url: string, params: { [param: string]: string | number | boolean } = {}, requestParams: object = {}): Observable<T> {
    return this.http.get<T>(url, { params: params, ...requestParams }).pipe(catchError((err) => {
      this.errorHandler.showErrorMessage(err);
      throw err;
    }), shareReplay());
  }

  postData<T>(url: string, params: { [param: string]: string | number | boolean }, payload: unknown, requestParams: object = {}): Observable<T> {
    return this.http.post<T>(url, payload, { params: params, ...requestParams }).pipe(catchError((err) => {
      this.errorHandler.showErrorMessage(err);
      throw err;
    }), shareReplay());
  }
}
