import { Component, OnInit } from '@angular/core';
import { SaveService } from './save.service';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-save-button',
  template: `
    <button *ngIf="hasWriteRole" mat-icon-button (click)="save()">
        <mat-icon *ngIf="(isLoading$ | async) === false else loading">save</mat-icon>
    </button>
        <ng-template #loading>
          <div class="loader"><mat-spinner [diameter]="30"></mat-spinner></div>
        </ng-template>
  `,
  styles: [
    `
    .loader ::ng-deep .mat-progress-spinner circle, .mat-spinner circle {
    stroke: white;
}
    `
  ]
})
export class SaveButtonComponent implements OnInit {

  isLoading$!: Observable<boolean>;
  hasWriteRole!: boolean;

  constructor(private saveService: SaveService, private authService: AuthService) { }

  ngOnInit(): void {
    this.isLoading$ = this.saveService.isLoading$;
    this.hasWriteRole = this.authService.hasWriteRole();
  }

  save() {
    this.saveService.sendSignal();
  }
}
