import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VENDOR_QUOTE_MAPPING_COLDEFS } from './vendor-quote-mapping-col-defs';

@Component({
  selector: 'app-vendor-quote-mapping',
  templateUrl: './vendor-quote-mapping.component.html',
  styleUrls: ['./vendor-quote-mapping.component.scss']
})
export class VendorQuoteMappingComponent {
  url = environment.apiRootUrl + environment.apiEndpoints['vendorQuoteMapping'];
  colDefs = VENDOR_QUOTE_MAPPING_COLDEFS;
  keyColumns = [
    'QuotationKey',
    'AltQuotationKey'
  ]
}
