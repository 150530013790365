import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorQuoteMappingComponent } from './pages/vendor-quote-mapping/vendor-quote-mapping.component';
import { CurrencyUomMappingComponent } from './pages/currency-uom-mapping/currency-uom-mapping.component';
import { MsalGuard } from '@azure/msal-angular';
import { CurveMappingComponent } from './pages/curve-mapping/curve-mapping.component';

const routes: Routes = [
  { path: 'vendorQuoteMapping', title: 'Exposure Vendor Quote Mapping', component: VendorQuoteMappingComponent, canActivate: [MsalGuard] },
  { path: 'currencyUomMapping', title: 'Commodity Currency & UOM Mapping', component: CurrencyUomMappingComponent, canActivate: [MsalGuard] },
  { path: 'curveMapping', title: 'Curve Mapping', component: CurveMappingComponent, canActivate: [MsalGuard] },
  { path: '', redirectTo: '/vendorQuoteMapping', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
