import { ColDef } from "@ag-grid-community/all-modules";

export const CURRENCY_UOM_MAPPING_COLDEFS: ColDef[] =[
    {
        field: 'QuotationKey',
        headerName: 'Quotation Key'
    },
    {
        field: 'Uom',
        headerName: 'UOM',
        headerClass: 'editable-column',
        editable: true,
    },
    {
        field: 'Currency',
        headerName: 'Currency',
        headerClass: 'editable-column',
        editable: true,
    },
]