import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageDialogComponent } from 'src/app/core/components/error-message-dialog/error-message-dialog.component';

/**
 * Service that displays error dialog
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private dialog: MatDialog) { }
  showErrorMessage(error: unknown, title = 'An Error Occurred', message?: string) {
    this.dialog.open(ErrorMessageDialogComponent, {
      data: {
        error: JSON.stringify(error, null, 2),
        title: title,
        message: message
      }
    });
  }
}
