<mat-toolbar color="primary">
    <button mat-icon-button *ngIf="!hideStartDrawer" (click)="startDrawer.toggle()"><mat-icon>menu</mat-icon></button>
    <div class="header-main-content">
        <span class="env">{{env}}</span>
        <h1 class="header-title">RAR Table Maintenance</h1>
    </div>
    <app-save-button style="margin: 0 2rem;"></app-save-button>
    <button mat-icon-button *ngIf="!hideEndDrawer" (click)="endDrawer.toggle()"><mat-icon>more_vert</mat-icon></button>
</mat-toolbar>
<mat-drawer-container style="flex: 1 1 auto">
    <mat-drawer #startDrawer position="start">
            <au-tree-navigation [navigation]="navRoutes" innerStyle="width:350px"></au-tree-navigation>
    </mat-drawer>
    <mat-drawer-content>
        <au-container innerClass="em-u-margin-top-double">
            <router-outlet></router-outlet>
        </au-container>
    </mat-drawer-content>
    <mat-drawer #endDrawer position="end" mode="side" [opened]="true">
        <app-filter #filter></app-filter>
    </mat-drawer>
</mat-drawer-container>