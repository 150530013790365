import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { NavEntry, ScriptInjectorService } from '@exxonmobil/angular-unity';
import { Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterComponent } from './components/filter/filter.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('startDrawer') private startDrawer!: MatDrawer;
  @ViewChild('startDrawer') private endDrawer!: MatDrawer;
  @ViewChild('filter') private filter!: FilterComponent;

  env = environment.name;
  hideStartDrawer = false;
  hideEndDrawer = false;

  private navigationSubscription!: Subscription;

  navRoutes: NavEntry[] = [
    { label: 'Exposure Vendor Quote Mapping', route: '/vendorQuoteMapping' },
    { label: 'Commodity Currency & UOM Mapping', route: '/currencyUomMapping' },
    { label: 'Curve Mapping', route: '/curveMapping' },
  ]

  constructor(private renderer: Renderer2, private scriptInjectorService: ScriptInjectorService, private router: Router) { }

  ngOnInit(): void {
    this.scriptInjectorService.injectUnityJs(this.renderer, 'unity/js/em-unity-1.7.1.min.js');

    this.navigationSubscription = this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(() => {
      this.startDrawer.close();
      this.filter.clearFilter();
    })
  }

  ngOnDestroy(): void {
    this.navigationSubscription?.unsubscribe();
  }
}
