<div *ngIf="hasWriteRole" class="em-u-margin" style="display: flex; justify-content: end;">
    <button au-button buttonStyle="primary" text="Add Entry" (click)="createRow()" [disabled]="!data"></button>
</div>
<ag-grid-angular [gridOptions]="gridOptions" (gridReady)="gridReady = $event" [rowData]="data" [modules]="modules"
    style="height: 60vh; width: 100%;" class="ag-theme-material"
    (rowDataChanged)="$event.api.sizeColumnsToFit()"></ag-grid-angular>

<ng-template #insertDialog>
    <div class="insert-dialog">
        <h3 mat-dialog-title>Insert new row</h3>
        <div mat-dialog-content>
            <div class="dialog-form">
                <mat-form-field *ngFor="let column of columnDefs" appearance="fill">
                    <mat-label>{{column.headerName}}</mat-label>
                    <input
                        *ngIf="!['agSelectCellEditor','agRichSelectCellEditor'].includes(column.cellEditor); else select"
                        matInput [formControl]="formControls[column.field!]" (change)="updateError(column.field!)">
                    <ng-template #select>
                        <mat-select [formControl]="formControls[column.field!]">
                            <mat-option *ngFor="let val of column.cellEditorParams.values" [value]="val">
                                {{val}}
                            </mat-option>
                        </mat-select>
                    </ng-template>
                    <mat-error
                        *ngIf="formControls[column.field!].invalid">{{getError(formControls[column.field!])}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions>
            <div class="dialog-actions">
                <button au-button [mat-dialog-close]="false">Cancel</button>
                <button au-button buttonStyle="primary" (click)="submit()" [disabled]="newRowForm.invalid">Ok</button>
            </div>
        </div>
    </div>
</ng-template>