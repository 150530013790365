import { ColDef } from "@ag-grid-community/all-modules";

export const VENDOR_QUOTE_MAPPING_COLDEFS: ColDef[] = [
    {
        field: 'QuotationKey',
        headerName: 'Quotation Key'
    },
    {
        field: 'AltQuotationKey',
        headerName: 'Alt Quotation Key'
    },
    {
        field: 'Source',
        headerName: 'Source',
        headerClass: 'editable-column',
        editable: true,
    },
    {
        field: 'Granularity',
        headerName: 'Granularity',
        headerClass: 'editable-column',
        editable: true,
    },
    {
        field: 'ExposureGroup1',
        headerName: 'Exposure Group 1',
        headerClass: 'editable-column',
        editable: true,
    },
]