import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterDataModel } from './filter-data-model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _filterValues = new BehaviorSubject<FilterDataModel | null>(null);
  filterValues$ = this._filterValues.asObservable();

  get filterValues() {
    return this._filterValues.getValue();
  }

  setFilterValues(data: FilterDataModel| null) {
    this._filterValues.next(data);
  }

}
