import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

export enum Role {
  read = 'EOD.USERS.READ',
  write = 'EOD.USERS.WRITE'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msalService: MsalService) { }

  getRoles() {
    
    const account = this.msalService.instance.getAllAccounts()[0];
    if (!account) return [];
    return account.idTokenClaims?.roles ?? []
  }

  hasWriteRole() {
    return this.getRoles().includes(Role.write);
  }
}
