import { ColDef } from "@ag-grid-community/all-modules";

export const CURVE_MAPPING_COLDEFS: ColDef[] = [
    {
        field: 'QUOTE_SOURCE',
        headerName: 'Quote Source',
        headerTooltip: 'Quote Source',
    },
    {
        field: 'QUOTE_TYPE',
        headerName: 'Quote Type',
        headerTooltip: 'Quote Type',
    },
    {
        field: 'QUOTATION',
        headerName: 'Quotation',
        headerTooltip: 'Quotation',
    },
    {
        field: 'COMPONENT',
        headerName: 'Component',
        headerTooltip: 'Component',
    },
    {
        field: 'QUOTATION_GRANULARITY',
        headerName: 'Quotation Granularity',
        headerTooltip: 'Quotation Granularity',
        headerClass: 'editable-column',
        cellClassRules: {
            'invalid-cell': (params) => !params.value
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
            values: ['D', 'M'],
        },
        editable: true,
    },
    {
        field: 'COMP_QUOTE_SOURCE',
        headerName: 'Comp Quote Source',
        headerTooltip: 'Comp Quote Source',
        headerClass: 'editable-column',
        cellClassRules: {
            'invalid-cell': (params) => !params.value
        },
        editable: true,
    },
    {
        field: 'COMP_QUOTE_TYPE',
        headerName: 'Comp Quote Type',
        headerTooltip: 'Comp Quote Type',
        headerClass: 'editable-column',
        cellClassRules: {
            'invalid-cell': (params) => !params.value
        },
        editable: true,
    },
    {
        field: 'COMP_QUOTATION',
        headerName: 'Comp Quotation',
        headerTooltip: 'Comp Quotation',
        headerClass: 'editable-column',
        cellClassRules: {
            'invalid-cell': (params) => !params.value
        },
        editable: true,
    },
    {
        field: 'COMP_GRANULARITY',
        headerName: 'Comp Granularity',
        headerTooltip: 'Comp Granularity',
        headerClass: 'editable-column',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
            values: ['D', 'M'],
        },
        editable: true,
    },
    {
        field: 'COMP_FACTOR',
        headerName: 'Comp Factor',
        headerTooltip: 'Comp Factor',
        headerClass: 'editable-column',
        editable: true,
    },
]