import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter/filter.component';
import { MatInputModule } from '@angular/material/input';
import { UnityComponentsModule } from '@exxonmobil/angular-unity';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MaintenanceTableComponent } from './maintenance-table/maintenance-table.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { SaveButtonComponent } from './save-button/save-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';




@NgModule({
  declarations: [
    FilterComponent,
    MaintenanceTableComponent,
    SaveButtonComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    UnityComponentsModule,
    FormsModule,
    MatCardModule,
    AgGridModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule 
  ],
  exports: [
    FilterComponent,
    MaintenanceTableComponent,
    SaveButtonComponent
  ]
})
export class ComponentsModule { }
