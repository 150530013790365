<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <div class="error">
        <h3>Error:</h3>
        <pre>{{data.error}}</pre>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>