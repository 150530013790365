<div class="em-u-margin">
    <mat-card class="em-u-margin-bottom-double">
        <mat-card-header>
            <mat-card-title>Filter Rows</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput [(ngModel)]="filterValue">
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <div class="button-group">
        <button au-button buttonStyle="primary" text="Apply" (click)="apply()"></button>
        <button au-button text="Clear" (click)="clearFilter()"></button>
    </div>
</div>